/* .desImg1 {
  height: 700px;
}

@media (max-width: 768px) {
  .desImg1 {
    height: 350px;
  }
}
@media (max-width: 486px) {
  .desImg1 {
    height: 220px !important;
  }
}
@media (max-width: 391px) {
  .desImg1 {
    height: 200px !important;
  }
} */

.desImg1 {
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}
