/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span a {
  color: rgb(77, 77, 180) !important;

  font-weight: 501;
}

span a:hover {
  color: blue !important;
  border-bottom: 1px solid blue !important;
}
p {
  text-align: justify;
}
.adSec2 p {
  text-align: justify;
  padding: 0px 23px;
}
.adSec2 h6 {
  padding: 0px 23px;
}
.desPara {
  font-size: 35px;
  line-height: 40px;
  text-align: justify;
}
.adProducth1 {
  padding: 0px 23px;
}
.distributeH1 {
  font-size: 60px;
  line-height: 68px;
}
form label {
  font-size: 20px !important;
}

.homeImg1 {
  width: 100%;
  height: 275px;
}
.conImg1 {
  width: 100%;
  height: 400px;
}
.comImg1 {
  height: 400px;
}
.comImg2 {
  width: 100%;
  height: 450px;
}
@media (max-width: 1025px) {
  .desPara {
    font-size: 33px;
    line-height: 34px;
  }
}
@media (max-width: 991px) {
  .dMainCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeImg1 {
    width: 58%;
    height: 350px;
  }
  .putAd {
    padding: 0px 23px !important;
  }
  .dCenter2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desPara {
    font-size: 33px !important;
    line-height: 34px;
    text-align: left;
  }
  .distributeH1 {
    padding: 8px !important;
  }
  .conImg1 {
    width: 80%;
    height: 400px;
  }
  .mainFaq h5 {
    padding: 0px 23px;
  }
  .mainFaq p {
    font-size: 15px !important;
    line-height: 22px;
    padding: 0px 20px;
    text-align: justify !important;
  }
  .desCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 20px !important;
    line-height: 22px;
    text-align: justify;
    padding: 0px 23px;
  }
  h1 {
    padding: 0px 15px !important;
  }
  .adSec2 p {
    font-size: 20px !important;
    line-height: 22px;
    padding: 0px 23px;
  }
  .adSec2 h6 {
    padding: 0px 23px;
  }
}

@media (max-width: 768px) {
  .homeImg1 {
    width: 62%;
    height: 300px;
  }
  .putAd {
    padding: 0px 15px !important;
  }
  .desPara {
    font-size: 28px !important;
    line-height: 32px;
  }
  .distributeH1 {
    font-size: 40px !important;
    line-height: 45px;
  }
  .conImg1 {
    width: 60%;
    height: 350px;
  }
  .conImg1 {
    width: 60%;
    height: 350px;
  }
  h1 {
    padding: 0px 15px !important;
  }
  p {
    font-size: 18px !important;
    line-height: 22px;
    padding: 0px 15px;
  }
  .adSec2 p {
    font-size: 18px !important;
    line-height: 27px;
    padding: 0px 15px;
  }
  .adSec2 h6 {
    padding: 0px 15px;
  }

  .mainFaq h5 {
    font-size: 18px !important;
    line-height: 23px;
    padding: 0px 20px;
  }
  .mainFaq p {
    font-size: 15px !important;
    line-height: 21px;
    padding: 0px 23px;
    text-align: justify !important;
  }
  .comImg1 {
    height: 350px;
  }
  .comImg2 {
    height: 380px;
  }
  .dCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commBtn {
    width: auto;
    height: auto;
    padding: 10px 15px;
  }

  form label {
    font-size: 18px !important;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 12px;
  }
}
@media (max-width: 486px) {
  .homeImg1 {
    width: 68%;
    height: 200px;
  }
  .desPara {
    font-size: 20px !important;
    line-height: 25px;
  }
  .distributeH1 {
    font-size: 30px !important;
    line-height: 35px;
  }
  .conImg1 {
    width: 60%;
    height: 250px;
  }
  p {
    font-size: 14px !important;
    line-height: 19px;
    padding: 0px 20px;
  }
  h1 {
    padding: 0px 20px;
  }
  .adSec2 p {
    font-size: 14px !important;
    line-height: 19px;
    padding: 0px 13px;
  }
  .adSec2 h6 {
    padding: 0px 13px;
  }
  .mainFaq h5 {
    font-size: 16px !important;
    line-height: 20px;
  }
  .mainFaq p {
    font-size: 13px !important;
    line-height: 18px;
    padding: 0px 21px;
  }
  .comImg1 {
    height: 235px !important;
  }
  .comImg2 {
    height: 300px;
  }
  form label {
    font-size: 14px !important;
  }
}
@media (max-width: 391px) {
  .homeImg1 {
    width: 73%;
    height: 170px;
  }
  .comImg1 {
    height: 180px !important;
  }
  .comImg2 {
    height: 300px;
  }
}
@media (max-width: 330px) {
  .homeImg1 {
    height: 150px;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span a {
  color: rgb(77, 77, 180) !important;

  font-weight: 501;
}

span a:hover {
  color: blue !important;
  border-bottom: 1px solid blue !important;
}
p {
  text-align: justify;
}
.adSec2 p {
  text-align: justify;
  padding: 0px 23px;
}
.adSec2 h6 {
  padding: 0px 23px;
}
.desPara {
  font-size: 35px;
  line-height: 40px;
  text-align: justify;
}
.adProducth1 {
  padding: 0px 23px;
}
.distributeH1 {
  font-size: 60px;
  line-height: 68px;
}
form label {
  font-size: 20px !important;
}

.homeImg1 {
  width: auto !important;
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
  max-width: 80%;
}

.comImg1 {
  width: auto !important;
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}

@media (min-width: 992px) and (max-width: 1025px) {
  .desPara {
    font-size: 33px;
    line-height: 34px;
  }
}
@media (max-width: 991px) {
  .dMainCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .putAd {
    padding: 0px 23px !important;
  }
  .dCenter2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desPara {
    font-size: 33px !important;
    line-height: 34px;
    text-align: left;
  }
  .distributeH1 {
    padding: 8px !important;
  }

  .mainFaq h5 {
    padding: 0px 23px;
  }
  .mainFaq p {
    font-size: 15px !important;
    line-height: 22px;
    padding: 0px 20px;
    text-align: justify !important;
  }
  .desCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 20px !important;
    line-height: 22px;
    text-align: justify;
    padding: 0px 23px;
  }
  h1 {
    padding: 0px 15px !important;
  }
  .adSec2 p {
    font-size: 20px !important;
    line-height: 22px;
    padding: 0px 23px;
  }
  .adSec2 h6 {
    padding: 0px 23px;
  }
}

@media (min-width: 487px) and(max-width: 768px) {
  .putAd {
    padding: 0px 15px !important;
  }
  .desPara {
    font-size: 28px !important;
    line-height: 32px;
  }
  .distributeH1 {
    font-size: 40px !important;
    line-height: 45px;
  }

  h1 {
    padding: 0px 15px !important;
  }
  p {
    font-size: 18px !important;
    line-height: 22px;
    padding: 0px 15px;
  }
  .adSec2 p {
    font-size: 18px !important;
    line-height: 27px;
    padding: 0px 15px;
  }
  .adSec2 h6 {
    padding: 0px 15px;
  }

  .mainFaq h5 {
    font-size: 18px !important;
    line-height: 23px;
    padding: 0px 20px;
  }
  .mainFaq p {
    font-size: 15px !important;
    line-height: 21px;
    padding: 0px 23px;
    text-align: justify !important;
  }

  .dCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commBtn {
    width: auto;
    height: auto;
    padding: 10px 15px;
  }

  form label {
    font-size: 18px !important;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 12px;
  }
}
@media (max-width: 486px) {
  .desPara {
    font-size: 20px !important;
    line-height: 25px;
  }
  .distributeH1 {
    font-size: 30px !important;
    line-height: 35px;
  }

  p {
    font-size: 14px !important;
    line-height: 19px;
    padding: 0px 20px;
  }
  h1 {
    padding: 0px 20px;
  }
  .adSec2 p {
    font-size: 14px !important;
    line-height: 19px;
    padding: 0px 13px;
  }
  .adSec2 h6 {
    padding: 0px 13px;
  }
  .mainFaq h5 {
    font-size: 16px !important;
    line-height: 20px;
  }
  .mainFaq p {
    font-size: 13px !important;
    line-height: 18px;
    padding: 0px 21px;
  }

  form label {
    font-size: 14px !important;
  }
}
