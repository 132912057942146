.sec4Img img {
  width: auto !important;
  /* height: auto !important; */
  height: 220px;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}

.ad1Img {
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}

.disImg1 {
  width: auto !important;
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}

@media (max-width: 990px) {
  .sec4Img img {
    height: auto;
  }
}
