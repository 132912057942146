@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.navbar {
  box-shadow: none;
  background-color: white;
}

nav ul li a {
  font-size: 14px !important;
}

nav ul li a:hover {
  color: #6dc8ed !important;
  transition: 0.5s;
}

form input,
textarea {
  background: #ede9e993 !important;
}

/*  */

.Distributediv1 {
  background: url("https://static.wixstatic.com/media/bfcb9b_e29d4e3d0a884e2db0d947d3dfbf6f62~mv2.jpg/v1/fill/w_980,h_678,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bfcb9b_e29d4e3d0a884e2db0d947d3dfbf6f62~mv2.jpg");
  min-height: 600px;
  width: 100%;
  background-size: 100% 100%;
  background-position-y: center;
  background-attachment: fixed;
}

.Poligon1Advertise {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    100% 100%,
    10% 100%,
    0% 100%,
    0% 10%
  );
}

.billboard01 h1 {
  font-size: 115px;
}

@media (max-width: 768px) {
  .billboard01 h1 {
    font-size: 50px;
  }
  .Distributediv1 {
    min-height: 350px;
  }
}
@media (max-width: 486px) {
  .billboard01 h1 {
    font-size: 40px;
    line-height: 45px;
  }
  .Distributediv1 {
    min-height: 300px;
  }
}
@media (max-width: 391px) {
  .Distributediv1 {
    min-height: 250px !important;
  }
}
