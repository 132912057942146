.homeImg2 {
  height: auto;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}
/* 
@media (max-width: 768px) {
  .homeImg2 {
    width: 75% !important;
    height: 330px !important;
  }
  .static2 img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 486px) {
  .homeImg2 {
    height: 210px !important;
  }
  .static2 img {
    width: 84px;
    height: 84px;
  }
}
@media (max-width: 391px) {
  .homeImg2 {
    height: 180px !important;
  }
} */
