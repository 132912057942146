/* .conatctImg2 {
  width: 100%;
  height: 400px;
}
@media (max-width: 991px) {
  .conatctImg2 {
    width: 60vw;
    height: 350px;
  }
}
@media (max-width: 768px) {
  .conatctImg2 {
    width: 55vw;
    height: 350px;
  }
}
@media (max-width: 486px) {
  .conatctImg2 {
    width: 60vw;
    height: 220px;
  }
}
@media (max-width: 391px) {
  .conatctImg2 {
    height: 180px;
  }
} */
.conatctImg2 {
  width: auto !important;
  height: auto !important;
  background-size: cover;
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}
