.footerBg {
  background: black;
  min-height: 310px;
}

.followUs {
  width: 100px;
  height: 100px;
}
.footCon {
  color: #ffffff !important;
}
.fParaOne {
  font-size: 15px !important;
}
@media (max-width: 991px) {
  .footer p {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .footerCol2 {
    text-align: justify !important;
  }
  .fHeadOne {
    font-size: 17px !important;
  }
  .fParaOne {
    font-size: 12px !important;
    padding: 2px !important;
  }
  .footer p {
    font-size: 15px !important;
  }
}
@media (max-width: 486px) {
  .fHeadOne {
    font-size: 14px !important;
  }
  .fParaOne {
    font-size: 12px !important;
  }
  .footer p {
    font-size: 13px !important;
  }
}
